import { Navigate, Route, Routes } from 'react-router-dom';
import ConfirmPasswordPage from './pages/Autentication/ConfirmPassword';
import ForgetPasswordPage from './pages/Autentication/ForgetPassword';
import Login from './pages/Autentication/Login';
import SignUp from './pages/Autentication/SignUp';
import CollectForm from './pages/Collect/CollectForm';
import CollectPage from './pages/Collect/CollectPage';
import CollectEventForm from './pages/CollectEvent/CollectEventForm';
import CollectEventPage from './pages/CollectEvent/CollectEventPage';
import CompanyForm from './pages/Company/CompanyForm';
import CompanyPage from './pages/Company/CompanyPage';
import ContainerForm from './pages/Container/ContainerForm';
import ContainerPage from './pages/Container/ContainerPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import DashboardVilarejo from './pages/Dashboard/DashboardVilarejo';
import ErrorPage from './pages/Error';
import HomePage from './pages/Home';
import NeighborhoodPage from './pages/Neighborhood/NeighborhoodPage';
import NotFoundPage from './pages/NotFound';
import PackForm from './pages/Pack/PackForm';
import PackPage from './pages/Pack/PackPage';
import PlanForm from './pages/Plan/PlanForm';
import PlanPage from './pages/Plan/PlanPage';
import PositiveImpactForm from './pages/PositiveImpact/PositiveImpactForm';
import PositiveImpactPage from './pages/PositiveImpact/PositiveImpactPage';

import ResiduesPage from './pages/Residue/ResiduePage';
import ResiduesForm from './pages/Residue/ResidueForm';
import ResidueClassForm from './pages/ResidueClass/ResidueClassForm';
import ResidueClassPage from './pages/ResidueClass/ResidueClassPage';
import ResidueStateForm from './pages/ResidueState/ResidueStateForm';
import ResidueStatePage from './pages/ResidueState/ResidueStatePage';
import RouteForm from './pages/Route/RouteForm';
import RoutePage from './pages/Route/RoutePage';
import RouteEventForm from './pages/RouteEvent/RouteEventForm';
import RouteEventPage from './pages/RouteEvent/RouteEventPage';
import TreatmentForm from './pages/Treatment/TreatmentForm';
import TreatmentPage from './pages/Treatment/TreatmentPage';
import UnitForm from './pages/Unit/UnitForm';
import UnitPage from './pages/Unit/UnitPage';
import UserForm from './pages/User/UserForm';
import UserPage from './pages/User/UserPage';
import UserProfile from './pages/User/UserProfile';
import ImpactPage from './pages/Dashboard/ImpactPage';
import VehiclePage from './pages/Vehicle/VehiclePage';
import WeighingForm from './pages/Weighing/WeighingForm';
import WeighingPage from './pages/Weighing/WeighingPage';
import { isAuthenticated } from './services/auth';

const PrivateRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  } else {
    return children;
  }
};

const RoutesComponent = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <PrivateRoute>
            <NotFoundPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/collects"
        element={
          <PrivateRoute>
            <CollectPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/collects/:id"
        element={
          <PrivateRoute>
            <CollectForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/collects_events"
        element={
          <PrivateRoute>
            <CollectEventPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/collects_events/:id"
        element={
          <PrivateRoute>
            <CollectEventForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/companies"
        element={
          <PrivateRoute>
            <CompanyPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/companies/:id"
        element={
          <PrivateRoute>
            <CompanyForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/containers"
        element={
          <PrivateRoute>
            <ContainerPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/containers/:id"
        element={
          <PrivateRoute>
            <ContainerForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/error"
        element={
          <PrivateRoute>
            <ErrorPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/neighborhoods"
        element={
          <PrivateRoute>
            <NeighborhoodPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/packs"
        element={
          <PrivateRoute>
            <PackPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/packs/:id"
        element={
          <PrivateRoute>
            <PackForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/plans"
        element={
          <PrivateRoute>
            <PlanPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/plans/:id"
        element={
          <PrivateRoute>
            <PlanForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/positive_impacts"
        element={
          <PrivateRoute>
            <PositiveImpactPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/positive_impacts/:id"
        element={
          <PrivateRoute>
            <PositiveImpactForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <UserProfile />
          </PrivateRoute>
        }
      />
      <Route
        path="/residues"
        element={
          <PrivateRoute>
            <ResiduesPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/residues/:id"
        element={
          <PrivateRoute>
            <ResiduesForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/residue_classes"
        element={
          <PrivateRoute>
            <ResidueClassPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/residue_classes/:id"
        element={
          <PrivateRoute>
            <ResidueClassForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/residue_states"
        element={
          <PrivateRoute>
            <ResidueStatePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/residue_states/:id"
        element={
          <PrivateRoute>
            <ResidueStateForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/routes"
        element={
          <PrivateRoute>
            <RoutePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/routes/:id"
        element={
          <PrivateRoute>
            <RouteForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/routes_events"
        element={
          <PrivateRoute>
            <RouteEventPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/routes_events/:id"
        element={
          <PrivateRoute>
            <RouteEventForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/treatments"
        element={
          <PrivateRoute>
            <TreatmentPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/treatments/:id"
        element={
          <PrivateRoute>
            <TreatmentForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/units"
        element={
          <PrivateRoute>
            <UnitPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/units/:id"
        element={
          <PrivateRoute>
            <UnitForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <UserPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/users/:id"
        element={
          <PrivateRoute>
            <UserForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/vehicles"
        element={
          <PrivateRoute>
            <VehiclePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/weighings"
        element={
          <PrivateRoute>
            <WeighingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/weighings/:id"
        element={
          <PrivateRoute>
            <WeighingForm />
          </PrivateRoute>
        }
      />

      <Route path="/forget-password" element={<ForgetPasswordPage />} />
      <Route path="/confirm-password" element={<ConfirmPasswordPage />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard-mobile/17" element={<DashboardVilarejo />} />
      <Route path="/dashboard-mobile/:id" element={<DashboardPage />} />
      <Route path="/dashboard/:id" element={<ImpactPage />} />
    </Routes>
  );
};

export default RoutesComponent;
