import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Material-UI
import { Grid, Button } from '@mui/material';

// Icons
import { ReactComponent as HomeWelcomeIcon } from '../assets/homeIcons/HomeWelcomeIcon.svg';
import { ReactComponent as CaminhaoLixo } from '../assets/homeIcons/caminhao-de-lixo.svg';
import { ReactComponent as Co2 } from '../assets/homeIcons/co2 1.svg';
import { ReactComponent as ColetaLixo } from '../assets/homeIcons/coleta-de-lixo 1.svg';
import { ReactComponent as Oceano } from '../assets/homeIcons/oceano 1.svg';
import { ReactComponent as BemEstar } from '../assets/homeIcons/bem-estar 1.svg';
import { ReactComponent as Plantar } from '../assets/homeIcons/plantar 1.svg';
import { ReactComponent as Filter } from '../assets/homeIcons/carbon_filter.svg';
import { ReactComponent as DashBoard } from '../assets/homeIcons/tabler_chart-infographic.svg';
import { ReactComponent as MaterialGenericIcon } from '../assets/homeIcons/reciclar-papel.svg';

// Components
import CardsHomeInfo from '../components/CardsHomeInfo';
import CardsHomeMaterialsInfo from '../components/CardsHomeMaterialsInfo';
import PieDashBoard from '../components/PieDashBoard';
import PageStructure from '../components/PageStructure';
import { PageContainer, PageContent, PageFooter } from '../components/GlobalStyleds.jsx';

// Models
import { IWeighing } from '../models/Weighing';

// Services
import { getAuthUser } from '../services/auth';
import CollectEventService from '../services/CollectEventService';
import CollectService from '../services/CollectService';
import ResidueService from '../services/ResidueService';
import { ICollectTreatment } from '../models/CollectTreatment';
import CollectTreatmentService from '../services/CollectTreatmentService';
import CollectResiduesService from '../services/CollectResiduesService';

const cardsInfo = [
  {
    id: 1,
    title: 'Total coletado',
    icon: CaminhaoLixo,
    total: 0,
    unit: ' Kg'
  },
  {
    id: 2,
    title: 'Emissões Evitadas',
    icon: Co2,
    total: 0,
    unit: ' Kg CO²'
  },
  // {
  //   id: 3,
  //   title: 'Famílias beneficiadas',
  //   icon: BemEstar,
  //   total: 0,
  //   unit: ''
  // },
  {
    id: 3,
    title: 'Plástico retirado do meio ambiente',
    icon: Oceano,
    total: 0,
    unit: ' Kg'
  },
  {
    id: 4,
    title: 'Árvores salvas',
    icon: Plantar,
    total: 0,
    unit: ''
  },
  // {
  //   id: 6,
  //   title: 'Renda gerada para Recicladores',
  //   icon: ColetaLixo,
  //   total: 0,
  //   unit: 'R$'
  // }
];

const WelcomeContainer = styled.div`
  width: 100%;
  border-radius: var(--borderRadius-m);
  background: linear-gradient(to right, #133940 0%, #133940 61%);
  position: relative;

  .welcomeText {
    color: white;
    text-align: start;
    padding: 0.3rem;
  }

  .homeWelcomeIconSvg {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

const CardsInfoContainer = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 1rem;

  @media (max-height: 800px) {
    margin-top: 0.5rem;
  }
`;

const HomeMaterialAction = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;

  @media (max-height: 800px) {
    margin: 1rem 0 0.5rem 0;
  }

  .actionsContainer {
    display: flex;
    gap: 1rem;
  }
`;

const HomeInfoContainer = styled.div`
  width: 100%;
  margin: auto;
`;

export default function HomePage() {
  const user_id = getAuthUser().id;
  interface IMaterialInfo {
    id: number;
    description: string;
    value: number;
    percent: number;
    img: string;
  }
  const authUser = getAuthUser();

  const [mainCardsInfo, setMainCardsInfo] = useState(cardsInfo);
  const [qtyCollects, setQtyCollects] = useState<number>(0);
  const [materialInfo, setMaterialInfo] = useState<IMaterialInfo[]>([]);
  const [pieDashBoardOpen, setPieDashBoardOpen] = useState<Boolean>(false);
  const [collectTreatments, setCollectTreatments] = useState<ICollectTreatment[]>([]);

  async function fetchData() {
    let allResidues = await ResidueService.getAll();
    let allCollectEvents = await CollectEventService.getAll();
    const collectTreatmentsInfo = await CollectTreatmentService.getById(user_id);
    const collectResidues = await CollectResiduesService.getById(user_id);

    const totalAvoidCO2 = collectTreatmentsInfo.reduce((acc, item) => acc + item.positive_impact.avoid_co2, 0);
    const plasticValue = collectTreatmentsInfo.find((t) => t.title === 'Plástico')?.value || 0;

    const totalWeight = collectResidues.reduce((acc, item) => acc + item.value, 0);
    const formatedTotalWeight = parseFloat(totalWeight.toFixed(2));

    let materialInfo = [] as IMaterialInfo[];

    collectResidues.forEach((cr) => {
      materialInfo.push({
        id: cr.id,
        description: cr.title,
        value: parseFloat(cr.value.toFixed(2)),
        percent: parseFloat(cr.percent.toFixed(2)),
        img: cr.img
      });
    });

    let mainCardsInfo = cardsInfo;
    mainCardsInfo[0].total = formatedTotalWeight;
    mainCardsInfo[1].total = parseFloat(totalAvoidCO2.toFixed(2));
    // mainCardsInfo[2].total = 0;
    mainCardsInfo[2].total = parseFloat(plasticValue.toFixed(2));
    mainCardsInfo[3].total = parseFloat(totalAvoidCO2.toFixed(2));
    // mainCardsInfo[5].total = 0;
    setMainCardsInfo(mainCardsInfo);
    setQtyCollects(allCollectEvents.length);
    setMaterialInfo(materialInfo);
    setCollectTreatments(collectTreatmentsInfo);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handlePieDashBoardToggle = () => {
    setPieDashBoardOpen(!pieDashBoardOpen);
  };

  return (
    <PageStructure>
      <PageContainer>
        <PageContent>
          <WelcomeContainer>
            <h2 className="welcomeText">{`Resultados da Coleta Seletiva: ${authUser.name}!`}</h2>
            {/* <HomeWelcomeIcon className="homeWelcomeIconSvg" /> */}
          </WelcomeContainer>
          <CardsInfoContainer>
            <Grid container spacing={3}>
              {mainCardsInfo.map((card) => (
                <Grid item xs={12} sm={6} md={3} key={card.id}>
                  <CardsHomeInfo title={card.title} mainInfo={card.total + card.unit} Icon={card.icon} />
                </Grid>
              ))}
            </Grid>
          </CardsInfoContainer>
          <HomeMaterialAction>
            <h3>Total de Coletas: {qtyCollects}</h3>
            <div className="actionsContainer">
              <Button
                variant="outlined"
                startIcon={<Filter />}
                sx={{
                  color: '#005996',
                  borderColor: '#005996',
                  textTransform: 'none'
                }}>
                Filtro de materiais
              </Button>
              <Button
                variant="outlined"
                startIcon={<DashBoard />}
                sx={{
                  color: '#005996',
                  borderColor: '#005996',
                  textTransform: 'none'
                }}
                onClick={handlePieDashBoardToggle}>
                {pieDashBoardOpen ? 'Exibir Cards' : 'Exibir gráfico'}
              </Button>
            </div>
          </HomeMaterialAction>
          <HomeInfoContainer>
            {pieDashBoardOpen ? (
              <PieDashBoard values={materialInfo.map((m) => m.value)} labels={materialInfo.map((m) => m.description)} />
            ) : (
              <Grid container spacing={3}>
                {materialInfo.map((material) => (
                  <Grid item xs={12} sm={6} md={2} key={material.id}>
                    <CardsHomeMaterialsInfo title={material.description} mainInfo={material.value + ' Kg'} percent={material.percent + '%'} img={material.img} />
                  </Grid>
                ))}
              </Grid>
            )}
          </HomeInfoContainer>
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
